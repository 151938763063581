.App {
  min-height: 100%;
  width: 100%;
  min-width: 1024px;
  display: grid;
  grid-template-rows: 1fr auto;
  text-align: center;
  font: 500 14px/20px Roboto, sans-serif;
}

h1 {
  font: 500 32px/40px Roboto, sans-serif;
  color: white;
}

p {
  font: 500 16px/20px Roboto, sans-serif;
  color: whitesmoke;
}

.main-content {
  min-height: calc(100vh - 128px);
  width: 100%;
}

.section-wrapper {
  width: 100%;
}

#section-overview {
  background: #13b6db;
}

.landing-section {
  margin: 0 auto;
  display: flex;
  padding: 0 120px;
  max-width: 1400px;
}

.section-content {
  margin: 24px auto;
  padding: 12px 24px;
  width: 100%;
  max-width: 1500px;
  min-width: 1024px;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.ui.button.la-button {
  border-radius: 2px;
  text-transform: uppercase;
  padding: 8px 16px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  background: #13b6db;
  border: #13b6db;
  color: white;
  margin: auto;
}

.ui.button.la-button:hover {
  background: #11a1c2;
}
