.footer-wrapper {
  background: #002a32;
  height: 64px;
}

.app-footer {
  height: 100%;
  max-width: 1400px;
  min-width: 1024px;
  margin: auto;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
}

.app-footer div {
  display: flex;
  justify-content: flex-end;
}

.app-footer p,
.app-footer p:first-child {
  color: white;
  margin: auto;
  padding-right: 8px;
  font: 500 14px/20px Roboto, sans-serif !important;
}

#button-subscribe {
  font: 500 14px/20px Roboto, sans-serif !important;
  margin: auto;
}

#section-connect a {
  margin: auto 4px;
  background: #17444d;
}

#section-connect a:hover {
  background: #13b6db;
}

#section-connect i {
  color: white;
}
