.app-header {
  height: 64px;
  padding: 8px 16px;
  box-shadow: 0 2px 8px #2f636d;
  width: 100%;
  min-width: 1024px;
  display: flex;
  vertical-align: middle;
  z-index: 1001;
}

.app-header #header-actions {
  flex: 1;
}

.app-header #brand {
  height: 48px;
  padding: 0 16px 0 0;
  border-right: 1px solid #f0f0f0;
  margin: auto;
}
