.toolrow {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 220px 1fr 140px;
}

.card {
  background: #fff;
  border-radius: 2px;
  height: 64px;
  margin: auto 0;
  padding: 8px;
  position: relative;
  box-shadow: 0 3px 6px rgba(124, 124, 124, 0.16),
    0 3px 6px rgba(124, 124, 124, 0.23);
  text-align: center;
  display: flex;
  justify-content: flex-start;
}

.card h4 {
  font: 500 16px Roboto, sans-serif;
  margin: 0;
  color: #002a32;
}

.card p {
  font: 500 14px/20px Roboto, sans-serif;
  color: #8c8c8c;
}

.toolcard-brand {
  height: 100%;
  padding: 4px 8px;
  margin: auto;
}

.brand-card {
  justify-self: start;
  margin-right: auto;
}

.description-card {
  display: flex;
  flex-wrap: wrap;
}

.description-row1 {
  display: flex;
  flex-direction: row;
  flex: 1 1 800px;
}

.description-row1 > div:nth-child(2) {
  margin-left: auto;
}

.description-row2 {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
}

.description-text {
  justify-self: start;
  margin-right: auto;
  padding: 0 8px;
}

.description-github {
  justify-self: end;
  padding: 0 8px;
}

.description-tags {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 8px;
}

.description-license {
  justify-self: end;
  align-self: end;
  padding: 0 8px;
}

.card p.description-license {
  font: 500 12px Roboto, sans-serif;
  color: #b9b9b9;
  margin: auto 0 auto auto;
}

.launch-card {
  margin-left: 16px;
  height: 100%;
  width: 100%;
}

.button-launch {
  font: 500 14px/20px Roboto, sans-serif !important;
  margin: auto;
  height: fit-content;
}

.la-tag.ui.label.tiny {
  font: 500 10px Roboto, sans-serif;
  padding: 4px 6px;
  background: rgba(19, 182, 213, 0.1);
  color: #0086a9;
}

.ui.basic.button.description-github-button {
  font: 500 12px Roboto, sans-serif;
  padding: 4px 8px;
  margin: auto 0;
}
